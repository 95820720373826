<template>
  <Cond :where="where" @refresh="refresh">
    <el-table
      :data="tableData"
      style="width: 100%"
      border
      size="small"
      v-loading="where.loading"
    >
      <el-table-column prop="name" label="菜单名"> </el-table-column>
      <el-table-column prop="path" label="菜单路径"> </el-table-column>
      <el-table-column prop="click_nums" label="点击量"> </el-table-column>
      <el-table-column prop="update_time" label="最后点击时间">
        <template slot-scope="scope">
          {{ $func.format(scope.row.update_time) }}
        </template>
      </el-table-column>
    </el-table>
    <div class="mgt10 text-center">
      <el-pagination
        @current-change="loadList"
        :current-page.sync="where.page"
        background
        layout="prev, pager, next"
        :page-size="where.pageSize"
        :total="where.total"
      >
      </el-pagination>
    </div>
  </Cond>
</template>
<script>
import Cond from "./visicond.vue";
export default {
  components: {
    Cond,
  },
  data() {
    return {
      url: {
        visitlist: "/sysadminoperate/visitlist",
      },
      where: {
        name: "",
        page: 1,
        pageSize: 50,
        total: 0,
        loading: false,
      },
      tableData: [],
    };
  },
  created() {
    this.loadList();
  },
  methods: {
    refresh: function () {
      this.where.page = 1;
      this.loadList();
    },
    loadList: function () {
      this.where.loading = true;
      this.$rq.postAction(this.url.visitlist, this.where).then((rs) => {
        this.where.loading = false;
        if (rs.code == 1) {
          this.tableData = [];
          for (var i in rs.list) {
            this.tableData.push(rs.list[i]);
          }
          this.where.total = parseInt(rs.pageInfo.total);
        } else {
          this.$message({
            type: "warning",
            message: rs.err,
          });
        }
      });
    },
  },
};
</script>